import React from 'react'
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import get from 'lodash/get'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
// import Stack from '../components/Stack'
import Header from '../components/Header'
import { pages } from '../utils'
import styled from 'styled-components'
import { AwesomeButton } from 'react-awesome-button'



const Container = styled.div`
  max-width: 932px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
`
const ButtonAbout = styled(AwesomeButton)`
  width: 300px !important;
	margin-top: 50px !important;
`

class About extends React.Component {
	render() {
   	const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
		return (
				<Layout location={ this.props.location } active={ pages.about }>
					<div className="About">
						<SEO
						title="われわれの活動"
						  url={`${siteUrl}/about`}
						/>
						<Header
							title='われわれの活動'
						description="中小企業のIT・クラウド導入事情が進まない現状を危惧し、Googleクラウドを中心に、無料・安価なITツールを導入支援、使い方の教育、社内問題解決支援活動を行なっております。
「自分たちで解決できる問題は、自分たちで」をコンセプトに社内でIT活用・運営・開発を行える様支援する活動を行なっております。"
							color='#3384a0'/>
						<div className="container">
							<div className="row center-xs">
									<div className="About__img-container col-xs-12 col-md-6">
										<div className="About__img">
									<Img sizes={this.props.data.aboutImage.sizes } />
										</div>
									</div>
									<div className="About__interests col-xs-12 col-md-6 text-left first-md">
										<h3 className="About__interests__title">自社の問題解決支援</h3>
										<ul className="About__interests__list">
											<li className="About__interests__item" >
												<i className="ai"></i>パスワードの管理
											</li>
											<li className="About__interests__item" >
												<i className="ml"></i>ホームページSEO対策
											</li>
											<li className="About__interests__item" >
												<i className="ds"></i>GMail
											</li>
											<li className="About__interests__item" >
												<i className="cp"></i>Googleスプレッドシート
											</li>
											<li className="About__interests__item" >
												<i className="game"></i>チャットワーク
											</li>
											<li className="About__interests__item" >
												<i className="back"></i>エクセルマクロ
												</li>
										</ul>
								</div>
							</div>
					</div>
					<div className="container">
						<Header
							top='50px'
							bottom='30px'
							title='具体的内容'
							description='次の様なツールを導入して、社内のデータの共有化・効率化を図り、他社との差別化を計っていきます。ITに強い会社に育てるための支援活動を行なってまいります。' />
						
						<div className="row center-xs mt-5">
							<div className="About__img-container col-xs-12 col-md-6">
								<div>
									<Img sizes={this.props.data.lastpassImage.sizes} />
								</div>
							</div>
							<div className="About__interests2 col-xs-12 col-md-6 text-left">
								<h3 className="About__interests__title2">パスワードの管理の徹底支援</h3>
								<p className="About__contents-font">
									あなたは、パスワードをどのように管理しているでしょうか？
  								<br />
									「子供の名前＋誕生日」...「いろんなサイトのパスワードが同じ...」
  								<br />
									こんなずさんな管理では、危険です。
									なぜなのか。
  								<br />
									現代社会は、「クラウド」システムで成り立っています。どうでしょうか、あらゆるサイトで「ログインID・パスワード」を求められていませんか？
									<br />
									これらを、しっかり管理する方法があります。
									<br />
									<br />
									パスワード管理の徹底を教育いたします。
								</p>
							</div>
						</div>

						<div className="row center-xs mt-5">
							<div className="About__img-container col-xs-12 col-md-6">
								<div>
									<Img sizes={this.props.data.seoImage.sizes} />
								</div>
							</div>
							<div className="About__interests2 col-xs-12 col-md-6 text-left">
								<h3 className="About__interests__title2">ホームページのSEO対策は自分でやる</h3>
								<p className="About__contents-font">
									あなたの会社のホームページはGoogleに評価されているでしょうか？
  								<br />
									ホームページ屋さんに丸投げでしょうか？
  								<br />
									ホームページ屋さんはそこまでしっかり、対策は練ってくれません。それはなぜでしょうか？
									同業他社のホームページも管理しているからです。
  								<br />
									現在、あなたが所有するホームページが自社が想定する顧客に訴えかける内容になっているか？
									Googleが推奨するホームページの構成になっているか？
									<br />
									これらを外注に任せるのは、危険です。
									<br />
									<br />
									自社でホームページを更新できる体制を整えた上で、自分たちでSEO対策をする必要があります。
									「自分のことは自分で守れ」の精神です。
								</p>
							</div>
						</div>

						<div className="row center-xs mt-5">
							<div className="About__img-container col-xs-12 col-md-6">
								<div>
									<Img sizes={this.props.data.gsuiteImage.sizes} />
								</div>
							</div>
							<div className="About__interests2 col-xs-12 col-md-6 text-left">
								<h3 className="About__interests__title2">Gooleの便利な機能を使い倒す</h3>
								<p className="About__contents-font">
									パソコン、iPad、スマホ、現代の仕事ではたくさんの通信端末を利用し仕事を進めますが・・・
  								<br />
									1.メールが連動されていない
  								<br />
									2.エクセルのファイルがあっちこっちに散乱
  								<br />
									3.クラウドが上手に活用できていない
									<br />
									などの問題を抱えております。
									<br />
									<br />
									無料（安価）なGoogleの各種ツールを使い倒すだけで、あなたの会社の業務は劇的に変わります。
								</p>
							</div>
						</div>

						<div className="row center-xs mt-5">
							<div className="About__img-container col-xs-12 col-md-6">
								<div>
									<Img sizes={this.props.data.chatworkImage.sizes} />
								</div>
							</div>
							<div className="About__interests2 col-xs-12 col-md-6 text-left">
								<h3 className="About__interests__title2">社内コミュニケーションツールに、まだ「LINE」を使ってますか？</h3>
								<p className="About__contents-font">
									LINEでの仕事のやり取りは、危険かつ不便。その理由とは？
  								<br />
									1.乗っ取りが多い
  								<br />
									2.ご送信が発生しやすい。（仕事とプライベートが混在）
  								<br />
									3.添付ファイルが一定期間後、みれない
									<br />
									4.「既読」がプレッシャーとなる（社員さん側）
									<br />
									などの問題を抱えております。
									<br />
									<br />
									チャットワークは、セキュリティーレベル、添付ファイル、グループチャットなどの面で、断然仕事に向いております。仕事でLINEをつかうのはやめるべきです。
								</p>
							</div>
						</div>

						<div className="row center-xs mt-5">
							<div className="About__img-container col-xs-12 col-md-6">
								<div>
									<Img sizes={this.props.data.excelImage.sizes} />
								</div>
							</div>
							<div className="About__interests2 col-xs-12 col-md-6 text-left">
								<h3 className="About__interests__title2">エクセルはマクロと独自関数で自動化しよう。</h3>
								<p className="About__contents-font">
									エクセルには、裏にプログラムがかけます。（Visual Basicという言語を使います）
  								<br />
									難しそうですね。しかし、考え方を学べば簡単です。
  								<br />
									この技術が身につくと、仕事の業務の効率化は、数十倍に上がります。
  								<br />
									では、どんなことができるのか？これについては、ブログで改めてサンプルをお教えいたします。
									<br />
								
									<br />
									エクセルの自動化は、中小企業の業務効率化・合理化を行う上で必須条件です。
								</p>
							</div>
						</div>

						<div className="row center-xs mt-5">
							<div className="About__img-container col-xs-12 col-md-6">
								<div>
									<Img sizes={this.props.data.iphoneImage.sizes} />
								</div>
							</div>
							<div className="About__interests2 col-xs-12 col-md-6 text-left">
								<h3 className="About__interests__title2">スマホ・パソコンを上手に使い倒す学び</h3>
								<p className="About__contents-font">
									スマホやパソコンをもっと上手に使い、業務の効率化をすることができます。
  								<br />
									特にスマホには、まだまだみなさんが知らない、たくさんの便利機能がございます。
  								<br />
									また、MacBookなどのアップル製パソコン操作について、また、iPadやiPhoneとの連携について学ぶことができます。
  								<br />
									<br />
									スマホ・パソコンを上手に連携・活用して、業務効率化を計りましょう。
								</p>
							</div>
						</div>

						<ButtonAbout href="/contact" >お問い合わせはこちら</ButtonAbout>

						<Header
							title='会社概要'
						description="中小企業のIT・クラウド導入を推進する理念のもと、活動する会社です。"
							color='#3384a0'/>
						<div className="container">
							<div className="row center-xs">
								<div className="About__img-container col-xs-12 col-md-6">
									<div className="About__img">
										<Img sizes={this.props.data.companyImage.sizes} />
									</div>
								</div>
								<div className="About__interests col-xs-12 col-md-6 text-left first-md">
									<h3 className="About__interests__title">会社情報</h3>
									<ul className="About__interests__list">
										<li className="About__interests__item" >
											株式会社ソフトスタジオ
										</li>
										<li className="About__interests__item" >
											代表取締役　呉島　進
										</li>
										<li className="About__interests__item" >
											〒730-0803
										</li>
										<li className="About__interests__item" >
											広島市中区広瀬北町3-14-805
										</li>
										<li className="About__interests__item" >
											Tel:050-3562-5099  Fax:050-3488-5845
										</li>
										<li className="About__interests__item" >
											EMail:info@softstudio.co.jp
										</li>
										
									</ul>
								</div>
							</div>
						</div>
						<ButtonAbout href="/price" >プランはこちら</ButtonAbout>

					</div>
				</div>
				
				</Layout>
		)
	}
}

export const queryAbout = graphql`
  query QueryAbout {
   	site {
     	siteMetadata {
       	title
      	siteUrl
     	}
   	}
   	aboutImage: imageSharp(fluid: {originalName: { regex: "/about-image.jpg/" } }) {
   	   sizes(maxWidth: 960) {
   	      ...GatsbyImageSharpSizes_tracedSVG
   	   }
		 }
		 
		testImage: imageSharp(fluid: {originalName: { regex: "/test-img.jpg/" } }) {
   	   sizes(maxWidth: 960) {
   	      ...GatsbyImageSharpSizes_tracedSVG
   	   }
			}
			
		lastpassImage: imageSharp(fluid: {originalName: { regex: "/lastpass.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		gsuiteImage: imageSharp(fluid: {originalName: { regex: "/gsuite.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		chatworkImage: imageSharp(fluid: {originalName: { regex: "/chatwork.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		seoImage: imageSharp(fluid: {originalName: { regex: "/seo.jpeg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		excelImage: imageSharp(fluid: {originalName: { regex: "/excel.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		iphoneImage: imageSharp(fluid: {originalName: { regex: "/iphone.jpeg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		companyImage: imageSharp(fluid: {originalName: { regex: "/company.jpeg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
  }
`

export default About
